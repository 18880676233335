
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import LegendPlatform from './pie_legends/legendPlatform.vue';
import LegendForHome from './pie_legends/legendForHome.vue';
import LegendConversions from './pie_legends/legendConversions.vue';
import utils from '../../../../util';
import { EChartsOption } from 'echarts/types/dist/shared';
import Tooltips from '../tooltip/chartTooltip.vue';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';
// this should be a module import, no cross linking across packages!
import { Tooltip } from '../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { C360Icon } from '@c360/ui';
import { ConversionsBreakdownType, ConversionsBreakdownTypeRawData } from '../../../../store/modules/performance/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}
interface TransformedFormat {
  name: string;
  sortableValue: number;
  value: string;
  valueKey: string;
  valueKeyData: number;
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericPie',
  components: {
    NoChartData,
    LegendPlatform,
    LegendForHome,
    LegendConversions,
    Tooltips,
    ExportDownloadBtn,
    EditModuleBtn,
    C360Icon,
  },
  props: [
    'pieDataProp',
    'sectionConfig',
    'componentConfig',
    'title',
    'subTitle',
    'icon',
    'dataSource',
    'nameKey',
    'theme',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
    'loadingProps',
    'daterange',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    canvasWidth: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltipCache: any;
    radius: Array<number | string>;
    containerWidth: number;
    maxResults: number;
    currKeyIndex: number;
    filterByMenuOpen: boolean;
    initialRendered: boolean;
    canvasHeight: string;
    selectedTactic: object;
  } => ({
    chartInstance: undefined,
    canvasWidth: 'auto',
    tooltipCache: {},
    radius: [''],
    containerWidth: 0,
    maxResults: 6,
    currKeyIndex: 0,
    filterByMenuOpen: false,
    initialRendered: false,
    canvasHeight: '300px',
    selectedTactic: null,
  }),
  computed: {
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    computedComponentHeight(): number | string {
      if (this.componentConfig?.hidePieChart) {
        return 'auto';
      } else return this.componentHeight;
    },
    definedTitleTop(): string {
      if (this.isCustomPieChart) {
        return this.countedTotal > 0 ? `${this.countedTotal}\nCONVERSIONS` : '😞\nNO CONVERSIONS\n TRACKED YET';
      } else if (this.isExporting || this.isOrder) {
        return '';
      } else {
        return 'Hover to see \n more details';
      }
    },
    countedTotal(): number {
      if (this.rawDataCustomPieChart?.length > 0 && this.isCustomPieChart) {
        return this.rawDataCustomPieChart?.reduce((total, item) => total + item?.conversions, 0);
      } else return 0;
    },
    formattedTactic(): string {
      return utils.formatNumberWithCommas(this.selectedTactic?.valueKeyData);
    },
    formattedDaterange(): string {
      let formattedRange = '';
      switch (this.daterange) {
        case 'thisyear':
          formattedRange = 'Last 365 Days';
          break;
        case 'thisweek':
          formattedRange = 'Last 7 Days';
          break;
        case 'thismonth':
          formattedRange = 'Last 30 Days';
          break;
      }
      return formattedRange;
    },
    tooltips(): Array<Tooltip> {
      // if the api sends a tooltip, use it in addition to any metric-specific tooltips
      // replace any tokens like {count} with the actual value
      const dataFilledTooltips = utils.dataFillTooltips(this.componentConfig.tooltips, [
        {
          key: 'count',
          value: this.pieData.length - 1,
        },
      ]);
      let returnTips;

      // get tooltip based on selected metric
      if (this.filterByValueKeys) {
        let activeMetric = this.isOrder ? null : this.filterByValueKeys?.[this.currKeyIndex]?.toLowerCase();
        if (this.componentConfig?.cid?.includes('ott') && activeMetric === 'conversions') {
          activeMetric = 'conversionsott';
        }
        const activeMetrics = this.isOrder ? null : utils.getTooltipsFromMetric(activeMetric);
        if (this.componentConfig.tooltips) {
          if (activeMetrics) {
            returnTips = [utils.getTooltipsFromMetric(activeMetric), ...dataFilledTooltips];
          } else {
            returnTips = dataFilledTooltips;
          }
        } else {
          returnTips = [utils.getTooltipsFromMetric(activeMetric)];
        }

        // show tooltip with hidden metrics
        if (this.hiddenKeys.length > 1) {
          let strAnd = '';
          for (let i = 0; i < this.hiddenKeys.length; i++) {
            if (i === 0) {
              strAnd += utils.headerNamesMap(this.hiddenKeys[0]);
            } else if (this.hiddenKeys.length === i + 1) {
              strAnd += ` and ${utils.headerNamesMap(this.hiddenKeys[i])} `;
            } else {
              strAnd += `, ${utils.headerNamesMap(this.hiddenKeys[i])}`;
            }
          }
          let strOr = '';
          for (let i = 0; i < this.hiddenKeys.length; i++) {
            if (i === 0) {
              strOr += utils.headerNamesMap(this.hiddenKeys[0]);
            } else if (this.hiddenKeys.length === i + 1) {
              strOr += ` or ${utils.headerNamesMap(this.hiddenKeys[i])} `;
            } else {
              strOr += `, ${utils.headerNamesMap(this.hiddenKeys[i])}`;
            }
          }
          returnTips.push({
            title: 'Hidden values',
            message: `${strAnd} not shown in this module. The campaign doesn’t have any available ${strOr} data.`,
          });
        }
        // Necessary for DASH-2840, "Not Mobile" tooltip
        if (
          this.names.includes('(Not Mobile)') &&
          (this.title.toLowerCase() === 'performance by device' ||
            this.title.toLowerCase() === 'performance by device type')
        ) {
          returnTips.push({
            title: 'Not Mobile',
            message: 'These are non-mobile devices, like Desktop and Connected TV (CTV) devices.',
          });
        }

        if (this.componentConfig?.cid === 'digitalvideoPerformanceByDevicePie') {
          returnTips.push({
            title: 'Other',
            message: `In this module, it represents the sum of all the Device Types that are not amongst the top 5 Device Types.`,
          });
          returnTips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Device Types. "Other" in the exports represents Device Types that are unknown
              and cannot be categorized into one of the other Device Types listed.`,
          });
        }

        if (this.componentConfig?.cid === 'googlevideoPerformanceByDevicePie') {
          returnTips.push({
            title: 'Other',
            message: `In this module, it represents Device Types that are unknown and cannot be
              categorized into one of the other Device Types listed.`,
          });
          returnTips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Device Types. "Other" in the exports represents Device Types
              that are unknown and cannot be categorized into one of the other Device Types listed.`,
          });
        }

        if (this.componentConfig?.cid === 'digitalvideoPerformanceByPlatformPie') {
          returnTips.push({
            title: 'Other',
            message: `In this module, it represents the sum of all the Platforms that are not amongst the top 5 Platforms.`,
          });
          returnTips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Platforms. "Other" in the exports represents Platforms that are unknown and
              cannot be categorized into one of the other Platforms listed.`,
          });
        }

        if (this.componentConfig?.cid === 'simpgeofencePerformanceByDevicePie' && this.isSimplifi) {
          returnTips.push({
            title: 'Other',
            message: 'In this module, it represents the sum of all the Devices that are not amongst the top 5 Devices.',
          });
          returnTips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Devices. "Other" in the exports represents Devices that are unknown and cannot
              be categorized into one of the other Devices listed.`,
          });
        }

        if (this.feedSources.includes('GROUNDTRUTH')) {
          if (this.componentConfig?.cid === 'gtdisplayPerformanceByDevicePie') {
            returnTips.push({
              title: 'Other',
              message:
                'In this module, it represents the sum of all the Device Types that are not amongst the top 5 Device Types.',
            });
            returnTips.push({
              title: 'CSV/XLSX export',
              message: `Contains all Device Types. "Other" in the exports represents Device Types that are unknown
                and cannot be categorized into one of the other Device Types listed.`,
            });
          }

          if (this.componentConfig?.cid === 'gtvideoPerformanceByDevicePie') {
            returnTips.push({
              title: 'Other',
              message:
                'Represents Device Types that are unknown and cannot be categorized into one of the other Device Types listed.',
            });
            returnTips.push({
              title: 'CSV/XLSX export',
              message: `Contains all Device Types.`,
            });
          }
        }

        if (this.feedSources.includes('TRUEGEO')) {
          if (this.componentConfig?.cid === 'trugeofencePerformanceByDevicePie') {
            returnTips.push({
              title: 'Other',
              message:
                'Represents Device Types that are unknown and cannot be categorized into one of the other Device Types listed.',
            });
            returnTips.push({
              title: 'CSV/XLSX export',
              message: `Contains all Device Types. "Other" in the exports represents Device Types that are unknown and
                cannot be categorized into one of the other Device Types listed.`,
            });
          }
        }

        // tooltip for Other and CSV/XLSX Export DASH-3838
        if (this.feedSources.includes('GAMDISPLAY') || this.feedSources.includes('GAMVIDEO')) {
          if (
            this.componentConfig?.cid === 'gamdisplayPerformanceByDeviceCategoryPie' ||
            this.componentConfig?.cid === 'gamvideoPerformanceByDeviceCategoryPie'
          ) {
            returnTips.push({
              title: 'Other',
              message: `In this module, it represents the sum of all the Device Types that are not amongst the top 5 Device Types.`,
            });
            returnTips.push({
              title: 'CSV/XLSX export',
              message: `Contains all Device Types. "Other" in the exports represents Device Types that are
                unknown and cannot be categorized into one of the other Device Types listed.`,
            });
          }

          if (
            this.componentConfig?.cid === 'gamdisplayPerformanceByDevicePie' ||
            this.componentConfig?.cid === 'gamvideoPerformanceByDevicePie'
          ) {
            returnTips.push({
              title: 'Other',
              message: `In this module, it represents the sum of all the Devices that are not amongst the top 5 Devices.`,
            });
            returnTips.push({
              title: 'CSV/XLSX export',
              message: `Contains all Devices. "Other" in the exports represents Devices that are unknown and cannot be
                categorized into one of the other Devices listed.`,
            });
          }
        }

        if (this.componentConfig?.cid === 'socialPerformanceByDevicePie') {
          returnTips.push({
            title: 'Other',
            message: `In this module, it represents Device Types that are unknown and cannot
              be categorized into one of the other Device Types listed.`,
          });
          returnTips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Device Types. "Other" in the exports represents Device Types that are unknown
              and cannot be categorized into one of the other Device Types listed. `,
          });
        }

        if (this.componentConfig?.cid === 'socialPerformanceByGenderPie') {
          returnTips.push({
            title: 'Other',
            message: `In this module, it represents Genders that are unknown and cannot be
              categorized into one of the other Genders listed.`,
          });
          returnTips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Genders. "Other" in the exports represents Genders that
              are unknown and cannot be categorized into one of the other Genders listed. `,
          });
        }

        if (this.componentConfig?.cid === 'calltrackingCallsByDeviceTypeCallPie') {
          returnTips.push({
            title: 'Other',
            message: `In this module, it represents Device Types that are unknown
              and cannot be categorized into one of the other Device Types listed.`,
          });
          returnTips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Device Types. "Other" in the exports represents Device Types
              that are unknown and cannot be categorized into one of the other Device Types listed.`,
          });
        }
      }

      if (
        (this.feedSources.includes('XANDR') ||
          this.feedSources.includes('MAGNITE') ||
          this.feedSources.includes('TRITON')) &&
        this.componentConfig?.cid === 'audioImpressionsByDeviceTypePie'
      ) {
        returnTips.push({
          title: 'Other',
          message: `In this module, it represents the sum of all the Device Types that are not amongst the top 5 Device Types.`,
        });
        returnTips.push({
          title: 'CSV/XLSX export',
          message: `Contains all Device Types. "Other" in the exports represents Device Types that are unknown
            and cannot be categorized into one of the other Device Types listed.`,
        });
      }

      // DASH-4391: hide 'Impression' tooltip for perfByDaypart only on simplifi
      if (this.isSimplifi && this.componentConfig?.cid?.toLowerCase().includes('performancebydaypart')) return null;

      if (returnTips) {
        return returnTips;
      }

      return dataFilledTooltips || null;
    },
    loading(): boolean {
      if (this.isCustomPieChart) {
        return this.$store.state.performance?.isConversionsBreakdownLoading;
      } else if (this.loadingProps) {
        return this.loadingProps;
      } else {
        return utils.isWaitingOnData(this);
      }
    },
    isCustomPieChart(): boolean {
      return this.componentConfig?.dataSource === 'ConversionBreakdown';
    },
    isNewHome(): boolean {
      return this.componentConfig.dataSource === 'HOME.ByDeviceTypeImpression';
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.pieData.length > 0;
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if ((this.componentConfig.hideIfNoData && !this.hasEnoughData) || this.isPrinting) {
        return false;
      }
      return true;
    },
    largeFontSize(): number {
      // font size needs to adjust to the radius border thickness
      switch (this.Theme.config.pieCharts.thickness) {
        case 'thick':
          return 20;
        default:
          return 30;
      }
    },
    smallFontSize(): number {
      // font size needs to adjust to the radius border thickness
      switch (this.Theme.config.pieCharts.thickness) {
        case 'thick':
          return 15;
        case 'medium':
          return 17;
        default:
          return 19;
      }
    },
    stacked(): boolean {
      if (this.componentConfig.stacked) {
        return true;
      }
      if (
        !this.componentConfig.breakpoints ||
        !Array.isArray(this.componentConfig.breakpoints) ||
        this.componentConfig.breakpoints.length === 0
      ) {
        return false;
      }
      return this.componentConfig.breakpoints[0] === 'xl6' ? false : this.Theme.config.pieCharts.stacked;
    },
    chartColumnCount(): number {
      if (this.componentConfig?.hidePieChart) return 12;
      if (this.isCustomPieChart) {
        return 4;
      }
      if (this.isOrder) return 12;
      return this.stacked || this.isNewHome ? 12 : 7; // if stacked, give each column full width to force them to stack, otherwise 50% each
    },
    legendColumnCount(): number {
      return this.chartColumnCount === 12 ? 12 : 12 - this.chartColumnCount; // either full width or whatever space is left
    },
    hasBrandImage(): boolean {
      return this.componentConfig.hasBrandImage;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasStyle(): any {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
        'max-width': '850px',
        margin: 'auto',
      };
    },
    center(): string[] {
      return ['50%', '50%'];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    names(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.pieData.reduce((allNames: any, data: any) => {
        allNames.push(data.name);
        return allNames;
      }, []);
    },
    filterByValueKeys(): string[] {
      let keys = this.componentConfig.valueKey;
      // remove keys that don't have any data
      if (!this.isExporting) {
        const hideEmptyKeys = this.componentConfig.valueKey;
        const slices = utils.adDataForKey(this, this.dataSource);
        hideEmptyKeys.forEach((k: string) => {
          if (slices?.find(x => typeof x[k] === 'undefined')) {
            keys = keys.filter(x => x !== k);
          }
        });

        // do not allow filtering by conversions if XANDR but all conversions = 0
        if (this.isXandr) {
          if (slices?.every(slice => slice?.Conversions === 0)) {
            keys = utils.filterOutColumns(keys, ['Conversions']);
          }
        }
        // filter out Visits if all are 0 for broadcast product
        if (
          this.componentConfig?.cid.toLowerCase().includes('broadcast') &&
          slices?.every(slice => slice?.Visits === 0)
        ) {
          keys = utils.filterOutColumns(keys, ['Visits']);
        }
      }
      if (this.hideSpend) {
        keys = utils.filterOutColumns(keys, ['CostPerClick']);
      }
      // hide Clicks and CTR for GAM Video
      if (this.componentConfig.dataChecks === 'GAMVIDEO') {
        keys = utils.filterOutColumns(keys, ['Clicks', 'ClickThrough']);
      }
      if (!this.isXandr) {
        keys = utils.filterOutColumns(keys, ['Conversions', 'CVR']);
      }
      return keys;
    },
    isOrder(): boolean {
      return this.$route?.query?.tab?.includes('order');
    },
    hiddenKeys(): any {
      const setA = new Set(this.filterByValueKeys);
      const diffSet = new Set(this.componentConfig.valueKey.filter(el => !setA.has(el)));
      const result = [...diffSet];
      return result;
    },
    pieConfig(): EChartsOption {
      const titleTop = this.definedTitleTop;
      const textColor = '#000';
      const bgColor = '#fff';
      const roseLabel = {
        normal: {
          position: 'outer',
          alignTo: 'labelLine',
          bleedMargin: 5,
        },
        emphasis: {
          show: false,
        },
      };

      const isYoutubeAgePieChart = this.componentConfig?.dataSource === 'GOOGLEVIDEO.ByAgeImpression';
      const customPieChart = this.isCustomPieChart;
      const newHome = this.isNewHome;
      const orderPage = this.isOrder;

      const usualLabel = {
        normal: {
          show: true,
          position: 'center',
          formatter(): string {
            return `{a|${titleTop}}`;
          },
          rich: {
            a: {
              fontSize: '14px',
              fontFamily: 'Inter',
              color: '#a2a2a2',
              fontWeight: '400',
            },
          },
        },
        emphasis: {
          height: 50,
          width: newHome || orderPage || customPieChart ? 100 : 150,
          scale: newHome || orderPage || customPieChart,
          label: { verticalAlign: 'middle', lineHeight: newHome || orderPage || customPieChart ? 60 : 100 },
          show: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter(params: any): string {
            let dataName = params.data?.name?.length > 18 ? `${params.data.name.slice(0, 15)}...` : params.data.name;
            const valKey = params.data.valueKey;

            if (newHome || orderPage || customPieChart) {
              return `{valc1|${parseFloat(params.data.value).toFixed(2)}%}\n{perc1|${dataName}}`;
            }
            // DASH-3224: change label text for Age piechart for YouTube Tactic
            if (isYoutubeAgePieChart) {
              dataName = dataName === 'Other' ? dataName : utils.youtubeAgeMapping[dataName];
            }
            // will hide top values for Link CTR and CTR DASH-2511
            if (valKey === 'LinkClickThrough' || valKey === 'ClickThrough') {
              if (dataName.length > 7) {
                return `\n${dataName}`;
              }
              return `\n{per|${dataName}}`;
            }
            return `{perc|${dataName}}\n{valc|${parseFloat(params.data.value).toFixed(2)}%}`;
          },
          textStyle: {
            fontSize: this.smallFontSize,
            fontFamily: 'Inter',
            color: textColor,
            fontWeight: 'normal',
            padding: [15, 15],
            lineHeight: this.smallFontSize,
          },
          backgroundColor: this.isExporting || this.isOrder ? null : bgColor,
          rich: {
            per: {
              fontSize: this.largeFontSize,
              fontWeight: 'normal',
            },
            perc: {
              fontSize: '14px',
              fontWeight: '400',
              color: '#3D3D3D',
            },
            valc: {
              color: '#3D3D3D',
              fontSize: '24px',
              fontWeight: '700',
            },
            perc1: {
              fontSize: '10px',
              fontWeight: '600',
              color: '#3E5769',
              lineHeight: 12,
            },
            valc1: {
              color: '#3E5769',
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: 30,
            },
          },
        },
      };
      // change label based on what type of pie chart we want to show
      let labelToShow: boolean | object;
      labelToShow = usualLabel;
      if (this.Theme.config.pieCharts.roseType) {
        labelToShow = roseLabel;
      }

      const cfg: EChartsOption = {
        color: this.pieColors,
        animation: !this.isExporting,
        animationDuration: this.isExporting ? 1 : 1000,
        animationDurationUpdate: this.isExporting ? 1 : 800,
        graphic: this.isCustomPieChart
          ? [
              {
                type: 'group',
                left: 'center',
                top: 'center',
                children: [
                  ...Array.from({ length: 90 }, (_, i) => ({
                    type: 'line',
                    shape: {
                      x1: 0,
                      y1: 90,
                      x2: 0,
                      y2: 94,
                    },
                    style: {
                      stroke: '#999',
                      lineWidth: 1,
                    },
                    rotation: (i * Math.PI) / 30,
                    origin: [0, 0],
                  })),
                ],
              },
            ]
          : null,
        series: [
          {
            type: 'pie',
            clockwise: true,
            radius: this.radius,
            avoidLabelOverlap: this.radius[0] === 0 || this.Theme.config.pieCharts.roseType,
            center: this.center,
            startAngle: this.startAngle,
            data: this.pieData,
            roseType: this.Theme.config.pieCharts.roseType,
            itemStyle: this.isNewHome
              ? {}
              : {
                  borderWidth: this.pieData.length === 1 ? 0 : 1,
                  borderColor: bgColor,
                },
            label: labelToShow,
            stillShowZeroSum: customPieChart || false,
          },
        ],
      };
      // cfg.tooltip.formatter = this.tooltip;
      cfg.tooltip = {
        formatter: this.tooltip,
        show: false,
      };
      cfg.height = this.canvasHeight;
      cfg.width = this.canvasWidth;

      cfg.legend =
        this.isNewHome || this.isCustomPieChart
          ? { show: false }
          : {
              orient: this.componentConfig.legendPosition === 'left' ? 'vertical' : 'horizontal',
              left: this.componentConfig.legendPosition === 'left' ? '15' : 'center',
              top: this.componentConfig.legendPosition === 'left' ? 'center' : 'bottom',
              data: this.names,
              show: false,
            };

      if (this.isExporting) {
        if (typeof this.componentConfig?.legend?.orient !== 'undefined') {
          cfg.legend.orient = this.componentConfig.legend.orient;
        }
        if (typeof this.componentConfig?.legend?.align !== 'undefined') {
          cfg.legend.align = this.componentConfig.legend.align;
        }
        if (typeof this.componentConfig?.legend?.left !== 'undefined') {
          cfg.legend.left = this.componentConfig.legend.left;
        }
        if (typeof this.componentConfig?.legend?.top !== 'undefined') {
          cfg.legend.top = this.componentConfig.legend.top;
        }
      }

      return cfg;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    startAngle(): any {
      if (this.componentConfig.startAngle) {
        return parseInt(this.componentConfig.startAngle, 10);
      }
      return 90;
    },
    rawDataCustomPieChart(): ConversionsBreakdownTypeRawData | [] {
      if (this.isCustomPieChart) {
        return this.$store.state.performance?.conversionsBreakdown || [];
      }
      return [];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pieData(): any {
      if (this.pieDataProp?.length) {
        return this.pieDataProp;
      }
      if (this.isCustomPieChart && this.rawDataCustomPieChart.length) {
        const pieChartTransformed = this.rawDataCustomPieChart?.map(i => this.transformData(i));
        return pieChartTransformed;
      }
      let slices = utils.adDataForKey(this, this.dataSource);

      if (!slices || !Array.isArray(slices)) {
        return [];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      slices = slices.map((x: any) => ({ ...x })); // shallow clone, for transforms to stay localized to this copy of the data

      const valueKey = this.componentConfig.valueKey[this.currKeyIndex];
      const saferSort = (key: string) => (a, b) => {
        const aDef = a && typeof a[key] !== 'undefined';
        const bDef = b && typeof b[key] !== 'undefined';
        if (!aDef && !bDef) {
          return 0;
        }
        if (!aDef) {
          return 1;
        }
        if (!bDef) {
          return -1;
        }
        return a[key].localeCompare(b[key]);
      };

      if (valueKey === 'ClickThrough') {
        slices.sort(saferSort('ClickThrough')).reverse();
      } else if (valueKey === 'LinkClickThrough') {
        slices.sort(saferSort('LinkClickThrough')).reverse();
      } else if (this.componentConfig.nameKey !== 'Daypart') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        slices.sort((x: any, y: any) => x[valueKey] - y[valueKey]).reverse();
      }
      if (
        this.componentConfig.nameKey === 'Daypart' &&
        this.componentConfig.dataSource === 'BROADCAST.ByDaypartVisitsAiring'
      ) {
        // ensure Daypart order for broadcast tacric is correct
        const daypartOrder = {
          overnight: 1,
          'early morning': 2,
          daytime: 3,
          'early fringe': 4,
          'early news': 5,
          'prime access': 6,
          prime: 7,
          'late fringe': 8,
        };
        slices.sort(function sortByDaypart(a, b) {
          const dp1 = a.Daypart.toLowerCase();
          const dp2 = b.Daypart.toLowerCase();
          return daypartOrder[dp1] - daypartOrder[dp2];
        });
      } else if (
        ['PREROLL.ByDaypartImpression', 'SIMPGEOFENCE.ByDaypartImpression', 'DISPLAY.ByDaypartImpression'].includes(
          this.dataSource,
        ) &&
        this.isSimplifi
      ) {
        // ensure Daypart order for preroll tactic is correct
        const daypartOrder = {
          '12am to 4am': 1,
          '4am to 8am': 2,
          '8am to 12pm': 3,
          '12pm to 4pm': 4,
          '4pm to 8pm': 5,
          '8pm to 12am': 6,
        };
        slices.sort(function sortByDaypart(a, b) {
          const dp1 = a.Daypart.toLowerCase();
          const dp2 = b.Daypart.toLowerCase();
          return daypartOrder[dp1] - daypartOrder[dp2];
        });
      } else if (this.componentConfig.nameKey === 'Daypart') {
        // ensure Daypart order is correct
        const daypartOrder = {
          morning: 1,
          midday: 2,
          afternoon: 3,
          night: 4,
          overnight: 5,
        };
        slices.sort(function sortByDaypart(a, b) {
          const dp1 = a.Daypart.toLowerCase();
          const dp2 = b.Daypart.toLowerCase();
          return daypartOrder[dp1] - daypartOrder[dp2];
        });
      } else if (this.componentConfig.nameKey === 'Day') {
        // ensure Day order is Sun - Sat
        const daypOrder = {
          sunday: 1,
          monday: 2,
          tuesday: 3,
          wednesday: 4,
          thursday: 5,
          friday: 6,
          saturday: 7,
        };
        slices.sort(function sortByDay(a, b) {
          const dp1 = a.Day.toLowerCase();
          const dp2 = b.Day.toLowerCase();
          return daypOrder[dp1] - daypOrder[dp2];
        });
      } else if (this.dataSource === 'GOOGLEVIDEO.ByAgeImpression') {
        const ageOrder = {
          AGE_RANGE_18_24: 1,
          AGE_RANGE_25_34: 2,
          AGE_RANGE_35_44: 3,
          AGE_RANGE_45_54: 4,
          AGE_RANGE_55_64: 5,
          AGE_RANGE_65_UP: 6,
          AGE_RANGE_UNDETERMINED: 7,
        };
        slices.sort(function sortByDay(a, b) {
          const age1 = a.Age;
          const age2 = b.Age;
          return ageOrder[age1] - ageOrder[age2];
        });
      }

      let rootTotal;
      if (valueKey === 'Aired') {
        const tempSlices = slices.map(slice => parseFloat(slice[valueKey]));
        rootTotal = tempSlices.reduce((part, item) => part + item, 0);
      } else if (
        valueKey === 'ClickThrough' ||
        valueKey === 'CostPerClick' ||
        valueKey === 'LinkClickThrough' ||
        valueKey === 'CostPerView'
      ) {
        // NOTE: we should get this data from backend ideally
        rootTotal = slices.reduce((sum, item) => {
          if (typeof item[valueKey] !== 'string') {
            return sum;
          }
          return sum + parseFloat(item[valueKey].replace(/[!@#$%^&*]/g, ''));
        }, 0);
        if (!rootTotal) {
          rootTotal = utils.adDataForKey(this, this.rootValueKey).replace(/[!@#$%^&*]/g, '');
        }
      } else {
        rootTotal = utils.adDataForKey(this, this.rootValueKey);
        if (typeof rootTotal === 'object') {
          rootTotal = rootTotal[valueKey];
        }
        if (typeof rootTotal === 'string') {
          rootTotal = parseFloat(rootTotal.replace(/[!@#$%^&*]/g, ''));
        }
      }

      if (rootTotal) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const names = slices.map((ad: any): string => ad[this.componentConfig.nameKey]);
        const useDupNamesTemplate = new Set(names).size !== names.length;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        slices = slices.map((ad: any): any => {
          let name = ad[this.componentConfig.nameKey];
          if (useDupNamesTemplate && this.componentConfig.dupNameTemplate) {
            name = utils.renderTemplate(this.componentConfig.dupNameTemplate, ad);
          }
          switch (name?.toLowerCase()) {
            case 'unknown':
            case 'unknowns':
              // list other 'n/a' names
              name = 'Other';
              break;
            case 'undetermined':
              // list other 'n/a' names
              name = 'Undetermined ';
              break;
            default:
              break;
          }

          let sliceValue = parseFloat(ad[valueKey]);
          if (typeof ad[valueKey] === 'string') {
            sliceValue = parseFloat(ad[valueKey].replace(/[!@#$%^&*]/g, ''));
          }
          sliceValue = (100 * sliceValue) / rootTotal;

          return {
            name,
            value: sliceValue.toFixed(8), // prettier-ignore
            valueKeyData: ad[valueKey],
            valueKey,
            sortableValue: sliceValue,
          };
        });
      } else {
        return [];
      }

      // move 'unknowns' to the bottom

      const unknown = slices.findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (ad: any) => ad.name === 'Unknown' || ad.name === 'Other' || ad.name === 'Undetermined ',
      );
      if (unknown !== -1) {
        slices.push(slices.splice(unknown, 1)[0]);
      }

      // dont need 'Other'
      if (
        [
          'SOCIAL.ByAgeImpression',
          'SEM.ByAgeConversionRate',
          'OTT.ByDaypartImpression',
          'BROADCAST.ByDaypartVisitsAiring',
          'PREROLL.ByDaypartImpression',
          'SIMPGEOFENCE.ByDaypartImpression',
          'DISPLAY.ByDaypartImpression',
        ].includes(this.dataSource)
      ) {
        slices = slices.filter(s => s.name !== 'Other');
        return slices;
      }

      let { maxResults } = this;
      if (this.componentConfig.limitResults) maxResults = this.componentConfig.limitResults;
      if (this.dataSource === 'GOOGLEVIDEO.ByAgeImpression') maxResults = 7;
      if (this.isXLS) {
        maxResults = 10000;
      }

      let totalOther = 0.0; // this will add up the left over percentages
      let totalCount = 0;
      const tempSlices = slices.splice(0, parseInt(maxResults, 10) - 1); // prettier-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tempSlices.reduce((all: any, ad: any) => {
        totalOther += parseFloat(ad.value);
        totalCount += parseFloat(ad.valueKeyData);
        return all;
      }, []);
      totalOther = 100.0 - totalOther; // much less computation this way
      slices = tempSlices;
      let tempValueKeyData = rootTotal - totalCount;
      if (Math.round(tempValueKeyData) !== tempValueKeyData) {
        // only round to last 2 decimals if value has decimals
        tempValueKeyData = parseFloat(tempValueKeyData.toFixed(2));
      }
      if (totalOther > 0.0000001 && !this.isXLS) {
        const existingOther = slices.find(slice => slice.name === 'Other');
        if (existingOther) {
          const summedValue = parseFloat(existingOther.value) + totalOther;
          existingOther.value = summedValue.toFixed(2);
          existingOther.valueKeyData = existingOther.valueKeyData + tempValueKeyData;
          existingOther.sortableValue = existingOther.sortableValue + totalOther;
        } else {
          slices.push({
            name: 'Other',
            value: totalOther.toFixed(2),
            valueKey,
            valueKeyData: tempValueKeyData,
            sortableValue: totalOther,
          });
        }
      }
      return slices;
    },
    filterable(): boolean {
      return this.componentConfig.filterable;
    },
    rootValueKey(): string {
      let rootValueKey = this.componentConfig.rootValueKey;
      if (Array.isArray(rootValueKey)) {
        rootValueKey = rootValueKey.find((key: string) => {
          return key.split('Total')[0] === this.$store.state.filters.selectedCampaigns[0].CampaignType;
        });
      }
      return this.filterable ? `${rootValueKey}.${this.componentConfig.valueKey[this.currKeyIndex]}` : rootValueKey;
    },
    pieColors(): string[] {
      // console.log('pie colors', this.Theme.config.chartColors);
      let colors = ['#FFD02A', '#FF6525', '#FC2197', '#B43AFF', '#E5176C', '#1E5AFF', '#36B20B'];
      if (this.componentConfig.nameKey === 'Gender') {
        const colorMap = {
          male: '#40c4ff',
          female: '#ff80ab',
          unknown: '#bdbdbd',
          other: '#bdbdbd',
          'undetermined ': '#bdbdbd',
        };
        colors = this.pieData.map(data => colorMap[(data.name || '').toLowerCase()]);
      } else if (this.isOrder) {
        colors = this.componentConfig?.chartColors;
      } else if (this.isNewHome) {
        colors = [
          '#FF738C',
          '#FF3D7F',
          '#E91E63',
          '#DB09C9',
          '#AA06F4',
          '#7005FA',
          '#5D36FB',
          '#00009D',
          '#000FD2',
          '#0060FB',
          '#2196F3',
          '#4AC7FF',
          '#0ADFD2',
          '#00BCD4',
          '#1CC700',
          '#4CAF50',
          '#8BC34A',
          '#CDDC39',
          '#FFEB3B',
          '#FFC107',
          '#FF9800',
          '#FF5722',
          '#CD8469',
          '#E6A38B',
          '#9E9E9E',
          '#C2BDBD',
        ];
      }
      return colors;
    },
    hideSpend(): boolean {
      // TODO: support multiple campaigns
      const campaignId = this.$store.state.filters.selectedCampaign?.id;
      const details = utils.campaignDetailsById(this)[campaignId];
      return details?.HideSpend;
    },
    isSimplifi(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('SIMPLIFI');
    },
    isXandr(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('XANDR');
    },
    feedSources(): string[] {
      return utils.feedSources(this);
    },
    hasCustomizedFeedSource(): boolean {
      return this.componentConfig?.feedSource?.length || this.componentConfig?.feedSourceToExclude?.length;
    },
    validFeedSource(): boolean {
      if (!this.componentConfig?.feedSource?.length && !this.componentConfig?.feedSourceToExclude?.length) return true;
      if (this.componentConfig?.feedSourceToExclude?.length) {
        return !this.componentConfig.feedSourceToExclude.some(item => this.feedSources.includes(item));
      }
      if (this.componentConfig?.feedSource?.length) {
        return this.componentConfig.feedSource.some(item => this.feedSources.includes(item));
      }
      return false;
    },
    isPrinting() {
      return this.$route.query.print === 'true';
    },
  },
  watch: {
    async $route() {
      if (this.isCustomPieChart) {
        if (this.isShared || (this.hasCustomizedFeedSource && !this.validFeedSource)) {
          this.$emit('set-display', false);
          return;
        } else {
          this.$emit('set-display', true);
          await this.fetchData();
        }
      }
    },
    radius: 'initChart',
    '$store.state.customer.theme': 'initChart',
    pieData: {
      immediate: true,
      handler(): void {
        this.initChart();
        if (this.isOrder && this.pieData?.length) {
          this.selectTactic(this.pieData[0].name);
        }
        if (this.isCustomPieChart && this.isShared) {
          this.$emit('set-display', false);
        }
        // hide right away if incorrect feed source
        // show if in edit mode whatever the data is
        // show if there is data, hide if not + hideIfNoData in component config is true
        if (this.hasCustomizedFeedSource && !this.validFeedSource) {
          this.$emit('set-display', false);
          return;
        }
        if (this.$store.state.layoutEditor.editMode) {
          this.$emit('set-display', true);
          return;
        }
        if (!this.pieData.length && this.componentConfig.hideIfNoData) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
    },
  },
  async created() {
    if (this.isCustomPieChart) {
      await this.fetchData();
    }
  },
  mounted() {
    this.initChart();
    window.addEventListener('optimizedResize', this.onWindowResize);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
    this.radius = this.computeRadius();

    if (this.componentConfig.selectedValueKey) {
      const found = this.componentConfig.valueKey.indexOf(this.componentConfig.selectedValueKey);
      if (found >= 0) {
        this.currKeyIndex = found;
      }
    }
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  methods: {
    async fetchData(): Promise<void> {
      const id = this.$route.query?.id || '';
      const daterange = this.$route.query?.daterange || 'alltime';
      const startdate = this.$route.query?.campaignstartdate || '';
      const enddate = this.$route.query?.campaignenddate || '';
      const campaignsIds = this.$route.query?.viewCampaigns?.split(',');
      const type = this.$route.query?.tab?.toUpperCase();

      const campaigns = campaignsIds?.map(c => {
        return {
          id: c,
          type: type,
        };
      });

      try {
        await this.$store.dispatch('performance/getConversionsBreakdown', {
          daterange: daterange,
          advertiserId: id,
          campaigns: campaigns || [],
          startdate: startdate,
          enddate: enddate,
        });
      } catch (error) {
        console.log(error);
      }
    },
    setContainerWidth(): void {
      if (typeof this.$refs.canvas === 'undefined') return;
      const pieChart = this.$refs.canvas as HTMLElement;
      if (pieChart && pieChart.offsetWidth && this.containerWidth !== pieChart.offsetWidth)
        this.containerWidth = pieChart.offsetWidth;
    },
    transformData(data: ConversionsBreakdownType): TransformedFormat {
      return {
        name: data?.categoryName,
        sortableValue: parseFloat(data.conversionsPercent.replace('%', '')),
        value: data.conversionsPercent.replace('%', ''),
        valueKey: 'conversionsPercent',
        valueKeyData: data?.conversions,
      };
    },
    initChart(): void {
      if (this.componentConfig.canvasWidth) {
        this.canvasWidth = this.componentConfig.canvasWidth;
      }
      if (this.componentConfig.canvasHeight) {
        this.canvasHeight = this.componentConfig.canvasHeight;
      }
      // need to wait for render due to v-if making refs unavailable
      this.$nextTick(() => {
        try {
          if (this.hasEnoughData && this.$refs.canvas) {
            this.setContainerWidth();
            this.chartInstance = ECharts.init(this.$refs.canvas);
            if (this.chartInstance) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const c = this.chartInstance as any;
              c.on('rendered', () => {
                if (this.initialRendered) {
                  return;
                }
                this.initialRendered = true;
                if (this.isXLS) {
                  this.getTableData();
                  return;
                }
                if (this.isExporting) {
                  setTimeout(() => {
                    let dataIndex = 0;
                    try {
                      let maxValue = -1;
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      this.pieData.forEach((x: any, i: number) => {
                        if (
                          x.sortableValue > maxValue &&
                          x.name !== 'Other' &&
                          x.name !== 'Unknown' &&
                          x.name !== 'Undetermined'
                        ) {
                          maxValue = x.sortableValue;
                          dataIndex = i;
                        }
                      });
                    } catch (exp) {
                      // eslint-disable-next-line no-console
                      console.error(exp);
                      dataIndex = 0;
                    }
                    // todo: highlight the highest value, not the first one
                    c.dispatchAction({
                      type: 'highlight',
                      dataIndex,
                    });
                  }, 10);
                }
                setTimeout(() => {
                  if (
                    !Array.isArray(this.pieData) ||
                    !this.pieData.length ||
                    typeof this.pieData[0].valueKeyData === 'undefined'
                  ) {
                    this.$emit('rendered', { empty: true });
                  } else {
                    this.$emit('rendered', { empty: false });
                  }
                }, 50);
              });
              c.setOption(this.pieConfig);
              c.on('click', params => {
                if (!this.isOrder) return;
                this.selectTactic(params.name);
              });
            }
          } else {
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 10);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('initChart', error);
        }
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          this.chartInstance.resize();
        }
      }, 250);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltip(params: any, ticket: string): any {
      if (this.tooltipCache[ticket]) return this.tooltipCache[ticket]; // haven't figured out how to throttle this. caching the return values may help a little
      let value = '';
      switch (this.componentConfig.tooltipType) {
        case 'daypart/visits':
          value = `${utils.getDaypartLabel(`${params.name}`)} ${parseFloat(params.value).toFixed(2)}% ${params.data.valueKeyData ? `(${params.data.valueKeyData} visits)` : ""}`; // prettier-ignore
          break;
        case 'daypart/aired':
          value = `${utils.getDaypartLabel(`${params.name}`)} ${parseFloat(params.value).toFixed(2)}% ${params.data.valueKeyData ? `(${params.data.valueKeyData} aired)` : ""}`; // prettier-ignore
          break;
        case 'daypart/impressions':
          value = `${utils.getDaypartLabel(`${params.name}`)} ${parseFloat(params.value).toFixed(2)}% ${params.data.valueKeyData ? `(${params.data.valueKeyData} impressions)` : ""}`; // prettier-ignore
          break;
        case 'daypart/hour':
          value = `${utils.getDaypartLabel(`${params.name}`)} ${parseFloat(params.value).toFixed(2)}% ${params.data.valueKeyData ? `(${params.data.valueKeyData} hours)` : ""}`; // prettier-ignore
          break;
        case 'creative/visits':
          value = `${params.name} ${parseFloat(params.value).toFixed(2)}% ${params.data.valueKeyData ? `(${params.data.valueKeyData} visits)` : ""}`; // prettier-ignore
          break;
        case 'creative/impressions':
          value = `${params.name} ${parseFloat(params.value).toFixed(2)}% ${params.data.valueKeyData ? `(${params.data.valueKeyData} impressions)` : ""}`; // prettier-ignore
          break;
        case 'creative/clicks':
          value = `${params.name} ${parseFloat(params.value).toFixed(2)}% ${params.data.valueKeyData ? `(${params.data.valueKeyData} clicks)` : ""}`; // prettier-ignore
          break;
        case 'auto':
        default:
          value = `${params.name} ${parseFloat(params.value).toFixed(2)}%`;
      }
      this.tooltipCache[ticket] = value;
      return value;
    },
    computeRadius(): string[] {
      if (this.isOrder) return ['50%', '80%'];
      if (this.isNewHome) {
        return ['55%', '85%'];
      }
      if (this.isCustomPieChart) {
        return ['65%', '95%'];
      } else return ['75%', '90%'];
    },
    legendHover(dataName): void {
      setTimeout(() => {
        if (!this.chartInstance) return;
        this.chartInstance.dispatchAction({
          type: 'highlight',
          name: dataName,
        });
      }, 400);
    },
    legendUnHover(dataName): void {
      setTimeout(() => {
        if (!this.chartInstance) return;
        this.chartInstance.dispatchAction({
          type: 'downplay',
          name: dataName,
        });
      }, 400);
    },
    filterByValue(value: string): void {
      const newIndex = this.componentConfig.valueKey.indexOf(value);
      if (newIndex !== this.currKeyIndex) {
        this.currKeyIndex = newIndex;
        this.initChart();
      }
    },
    headerName(name: string): string {
      if (
        this.sectionConfig.id === 'gtdisplay' ||
        this.sectionConfig.id === 'gtvideo' ||
        (this.isExporting && (this.exportData?.tab === 'gtdisplay' || this.exportData?.tab === 'gtvideo'))
      ) {
        if (name === 'Impressions') {
          return 'Geofencing Impressions';
        } else if (name === 'Imps') {
          return 'Geofencing Imps';
        }
      }
      if (name === 'Impressions') return name;
      if (this.componentConfig.cid === 'googlevideoPerformanceByGenderPie') {
        if (name === 'VCR') return 'View Rate';
        if (name === 'CostPerView') return 'CPV (Cost Per View)';
      }
      return utils.headerNamesMap(name);
    },
    getTableData(): void {
      let headers = [this.componentConfig.nameKey, ...this.componentConfig.valueKey];
      if (!this.isXandr) {
        headers = utils.filterOutColumns(headers, ['Conversions', 'CVR']);
      }
      const sourceData = utils.adDataForKey(this, this.dataSource);
      if (
        this.componentConfig?.cid.toLowerCase().includes('broadcast') &&
        sourceData?.every(slice => slice?.Visits === 0)
      ) {
        headers = utils.filterOutColumns(headers, ['Visits']);
      }
      const hash = {};

      if (Array.isArray(sourceData) && Array.isArray(this.componentConfig.valueKey)) {
        this.componentConfig.valueKey.forEach((valueKey: string) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let slices = sourceData.map((x: any) => ({ ...x }));
          let rootTotal;
          if (valueKey === 'ClickThrough' || valueKey === 'CostPerClick') {
            rootTotal = slices.reduce((sum, item) => {
              // console.log(item[valueKey], valueKey, item);
              if (typeof item[valueKey] === 'string') {
                return sum + parseFloat(item[valueKey].replace(/[!@#$%^&*]/g, ''));
              }
              return sum;
            }, 0);
          } else {
            let rootKey = this.componentConfig.rootValueKey;
            if (Array.isArray(rootKey)) {
              rootKey = rootKey.find((key: string) => {
                return key.split('Total')[0] === this.$store.state.filters.selectedCampaigns[0].CampaignType;
              });
            }
            rootKey = this.componentConfig.filterable ? `${rootKey}.${valueKey}` : rootKey;
            rootTotal = utils.adDataForKey(this, rootKey);
            if (typeof rootTotal === 'object') {
              rootTotal = rootTotal[valueKey];
            }
            if (typeof rootTotal === 'string') {
              rootTotal = parseFloat(rootTotal.replace(/[!@#$%^&*]/g, ''));
            }
          }

          if (rootTotal) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const names = slices.map((ad: any): string => ad[this.componentConfig.nameKey]);
            const useDupNamesTemplate = new Set(names).size !== names.length;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            slices = slices.map((ad: any): any => {
              let name = ad[this.componentConfig.nameKey];
              if (useDupNamesTemplate && this.componentConfig.dupNameTemplate) {
                name = utils.renderTemplate(this.componentConfig.dupNameTemplate, ad);
              }
              switch (name?.toLowerCase()) {
                case 'unknown':
                case 'unknowns':
                  // list other 'n/a' names
                  name = 'Other';
                  break;
                case 'undetermined':
                  name = 'Undetermined ';
                  break;
                default:
                  break;
              }

              let parsedValue = ad[valueKey];
              if (typeof parsedValue === 'string') {
                parsedValue = parseFloat(parsedValue.replace(/[!@#$%^&*]/g, ''));
              }
              const sliceValue = (100 * parsedValue) / rootTotal;

              return {
                name,
                value: sliceValue.toFixed(8),
                parsedValue: parsedValue,
                valueKeyData: ad[valueKey],
                valueKey,
                sortableValue: sliceValue,
              };
            });
          }
          // move 'unknowns' to the bottom
          const unknown = slices.findIndex(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (ad: any) => ad.name === 'Unknown' || ad.name === 'Other' || ad.name === 'Undetermined ',
          );
          if (unknown !== -1) {
            slices.push(slices.splice(unknown, 1)[0]);
          }

          // dont need 'Other' bc there are 6 age groups
          if (
            this.dataSource !== 'SOCIAL.ByAgeImpression' &&
            this.dataSource !== 'SOCIAL.ByAgeConversionRate' &&
            this.dataSource !== 'BROADCAST.ByDaypartVisitsAiring' &&
            this.dataSource !== 'GOOGLEVIDEO.ByGenderImpression'
          ) {
            let totalOther = 0.0; // this will add up the left over percentages
            let totalCount = 0;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            slices.reduce((all: any, ad: any) => {
              totalOther += parseFloat(ad.value);
              totalCount += parseFloat(ad.valueKeyData);
              return all;
            }, []);
            totalOther = 100.0 - totalOther; // much less computation this way
            let tempValueKeyData = rootTotal - totalCount;
            if (Math.round(tempValueKeyData) !== tempValueKeyData) {
              // only round to last 2 decimals if value has decimals
              tempValueKeyData = parseFloat(tempValueKeyData.toFixed(2));
            }

            if (totalOther > 0.0000001) {
              const otherSlide = slices.find(x => x.name === 'Other');
              if (otherSlide) {
                otherSlide.parsedValue = tempValueKeyData + otherSlide.parsedValue;
                otherSlide.valueKeyData = otherSlide.parsedValue;
                otherSlide.sortableValue = totalOther; // + otherSlide.totalOther;
                otherSlide.totalOther = otherSlide.sortableValue.toFixed(2);
              } else {
                slices.push({
                  name: 'Other',
                  value: totalOther.toFixed(2),
                  valueKey,
                  parsedValue: tempValueKeyData,
                  valueKeyData: tempValueKeyData,
                  sortableValue: totalOther,
                });
              }
            }
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          slices.forEach((slice: any) => {
            let row = hash[slice.name];
            if (!row) {
              row = hash[slice.name] = {};
              row[slice.valueKey] = slice.parsedValue;
            } else {
              if (typeof row[slice.valueKey] === 'number') {
                row[slice.valueKey] += slice.parsedValue;
              } else {
                row[slice.valueKey] = slice.parsedValue;
              }
            }
            // console.log(slice.name, slice.valueKey, row[slice.valueKey]);
          });
        });
      }

      let data = Object.keys(hash).map((key: string) => {
        const row = [key];
        headers.forEach((header: string) => {
          if (header !== this.componentConfig.nameKey) {
            row.push(hash[key][header]);
          }
        });
        return row;
      });

      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      }

      if (this.componentConfig.exportTweaks?.unknownToOther) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data = data.map((row: any) => {
          if (row[0] === 'Unknown') {
            row[0] = 'Other';
          }
          return row;
        });
      }

      if (this.componentConfig.exportTweaks?.sortByRange) {
        data = data.sort(function (a, b) {
          const x = a[0];
          const y = b[0];
          if (x === 'Undetermined') {
            return -1;
          } else if (y === 'Undetermined') {
            return 1;
          } else {
            try {
              const aFirstTwoDigits = parseInt(x.substring(0, 2), 10);
              const bFirstTwoDigits = parseInt(y.substring(0, 2), 10);
              return aFirstTwoDigits > bFirstTwoDigits ? 1 : bFirstTwoDigits > aFirstTwoDigits ? -1 : 0;
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.log('sortByRange', exp);
            }
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            // names must be equal
            return 0;
          }
        });
      } else if (this.componentConfig.exportTweaks?.sortBy) {
        const key = this.componentConfig.exportTweaks.sortBy;
        const index = headers.indexOf(key);
        if (index >= 0) {
          data = data.sort(function (a, b) {
            const v1 = a[index];
            const v2 = b[index];
            return v2 - v1;
          });
        }
      }

      if (this.dataSource === 'BROADCAST.ByDaypartVisitsAiring') {
        // ensure Daypart order for broadcast tactic is correct
        const daypartOrder = {
          overnight: 1,
          'early morning': 2,
          daytime: 3,
          'early fringe': 4,
          'early news': 5,
          'prime access': 6,
          prime: 7,
          'late fringe': 8,
        };
        data.sort(function sortByDaypart(a, b) {
          const dp1 = a[0].toLowerCase();
          const dp2 = b[0].toLowerCase();
          return daypartOrder[dp1] - daypartOrder[dp2];
        });
      }

      if (
        ['PREROLL.ByDaypartImpression', 'SIMPGEOFENCE.ByDaypartImpression', 'DISPLAY.ByDaypartImpression'].includes(
          this.dataSource,
        )
      ) {
        // ensure Daypart order for preroll tactic is correct
        const daypartOrder = {
          '12am to 4am': 1,
          '4am to 8am': 2,
          '8am to 12pm': 3,
          '12pm to 4pm': 4,
          '4pm to 8pm': 5,
          '8pm to 12am': 6,
        };
        data.sort(function sortByDaypart(a, b) {
          const dp1 = a[0].toLowerCase();
          const dp2 = b[0].toLowerCase();
          return daypartOrder[dp1] - daypartOrder[dp2];
        });
      }

      if (this.dataSource === 'GOOGLEVIDEO.ByAgeImpression') {
        const ageOrder = {
          AGE_RANGE_18_24: 1,
          AGE_RANGE_25_34: 2,
          AGE_RANGE_35_44: 3,
          AGE_RANGE_45_54: 4,
          AGE_RANGE_55_64: 5,
          AGE_RANGE_65_UP: 6,
          AGE_RANGE_UNDETERMINED: 7,
        };
        data.sort(function sortByDay(a, b) {
          const age1 = a[0];
          const age2 = b[0];
          return ageOrder[age1] - ageOrder[age2];
        });
        data = data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((row: any[]) => {
            row[0] = utils.youtubeAgeMapping[row[0]];
            return row;
          });
      }

      if (this.componentConfig.exportTweaks?.othersToBottom) {
        const otherRows = [];
        data = data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((row: any[]) => {
            if (row.length > 0 && utils.isUnknownOrOtherOrInvalidString(row[0], { otherOnly: true })) {
              otherRows.push(row);
              return [];
            }
            return row;
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((row: any) => row.length > 0);
        while (otherRows.length) {
          data.push(otherRows.shift());
        }
      }

      this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
    },
    selectTactic(name: string): void {
      const tactic = this.pieDataProp.find(t => t.name === name);
      this.selectedTactic = tactic;
    },
  },
});
