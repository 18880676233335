
import Vue from 'vue';
import { format, parse } from 'date-fns';
import utils from '../../../../util';
import { EChartsOption } from 'echarts/types/dist/shared';
import { Conversion, Conversions, TotalConversions } from '@point/utility-classes';
import { Resolution } from '../../../../store/modules/performance/types';
import NoChartData from '../no-data/NoChartData.vue';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericLineBarChartComponent',
  components: { NoChartData, ExportDownloadBtn },
  props: ['componentConfig', 'title', 'theme', 'breakpoints', 'isExporting', 'isGetNewData'],
  data: (): {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    canvasWidth: string;
    canvasHeight: string;
    componentHeight: string;
    periods: { title: string; value: Resolution }[];
    total: TotalConversions | null;
    conversions: Conversion[] | [];
    selectedResolution: Resolution;
  } => ({
    chartInstance: undefined,
    canvasWidth: 'auto',
    canvasHeight: '450px',
    componentHeight: '541px',
    periods: [
      { title: 'daily', value: Resolution.day },
      { title: 'weekly', value: Resolution.week },
      { title: 'monthly', value: Resolution.month },
    ],
    total: null,
    conversions: [],
    selectedResolution: Resolution.day,
  }),
  watch: {
    chartData: {
      immediate: true,
      handler(): void {
        this.initChart();
        if (
          this.chartData?.length === 0 &&
          this.componentConfig.hideIfNoData &&
          !this.$store.state.layoutEditor.editMode
        ) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
    },
    '$store.state.customer.theme': 'initChart',
    chartColors: {
      handler(): void {
        this.initChart();
      },
    },
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
  },
  async created() {
    await this.fetchData();
  },
  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
    this.initChart();
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    dashboardId(): number {
      return this.$store.state.customer?.campaignAdPerformance?.CampaignList[0]?.DashboardId;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    additionalMetrics(): [string] {
      return this?.componentConfig?.additionalMetrics || [];
    },
    hasAdditionalData(): boolean {
      // DASH-4704: ignore multilineDailyPerformance chart when filtering OTT/Innovid metrics
      if (['ottDailyPerformanceLineChart', 'ottPerformanceByDayOfWeekLineChart'].includes(this.componentConfig?.cid)) {
        return !(
          this?.sourceData?.some(obj => obj?.ResponseNumber === undefined) ||
          this?.sourceData?.every(obj => obj?.ResponseNumber === 0)
        );
      }
      return this?.componentConfig?.additionalMetrics && this?.sourceData?.some(row => row?.isEnriched);
    },
    // sourceData() {
    //   let data;

    //   if (this.isGetNewData) {
    //     return this.separateData;
    //   }
    //   // check for indivdual data
    //   const hasIndivdualCampaignData = this.tweaks?.includes('indivdual');
    //   if (hasIndivdualCampaignData) {
    //     data = utils.adDataForKey(this, `${this.dataSource}Campaign`);
    //     if (!Array.isArray(data)) {
    //       data = utils.adDataForKey(this, this.dataSource);
    //     }
    //   } else {
    //     data = utils.adDataForKey(this, this.dataSource);
    //   }
    //   return data;
    // },
    hasEnoughData(): boolean {
      return this.chartData[0] && this.chartData[0].length > 0;
    },
    isLoading(): boolean {
      return this.$store.state.performance.isConversionsLoading;
    },
    error(): string {
      return this.$store.state.performance.conversionsError;
    },
    canvasStyle(): Record<string, number> {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
      };
    },
    chartConfig(): EChartsOption {
      const colorSet = ['#CCCCCC', '#A5B3FD', '#FF634E'];

      const options: EChartsOption = {
        color: colorSet,
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          borderWidth: 0,
          order: 'seriesDesc',
          textStyle: {
            color: 'rgb(255, 255, 255, 1)',
          },
          show: true,
          formatter: function (params): string | null {
            // formatting the tooltip to handle for percent values and match current style.
            try {
              const tooltipArray: Array<string> = [];
              tooltipArray?.push(`${params[0].name}<table>`);
              params?.forEach(tipdata => {
                const { seriesName, marker, data } = tipdata;
                const value = seriesName === 'CVR' ? `${data.value}%` : data;
                const conversions = seriesName === 'CVR' ? data?.conversions : '';
                tooltipArray.push(
                  `<div>
                    <div><strong>${value}</strong> ${seriesName}</div>
                    <div>${conversions ? '<strong>' + conversions + '</strong>' + ' Conversions' : ''}</div>
                  </div>`,
                );
                return tooltipArray;
              });
              tooltipArray.push('</table>');
              return tooltipArray.join('');
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.error('tooltip formatter', exp, params);
            }
            return null;
          },
        },
        grid: {
          left: '4%',
          right: '3%',
        },
        legend: {
          bottom: 0,
          left: '4%',
          align: 'left',
          padding: [2, 2, 2, 2],
          itemGap: 14,
          textStyle: {
            color: '#3D3D3D',
            fontSize: 12,
            lineHeight: 12.5,
          },
          icon: 'circle',
          data: this.componentConfig?.availableBarSeriesKeys,
        },
        xAxis: {
          type: 'category',
          data: this.chartData[0] as string[],
          axisLabel: {
            show: true,
            color: '#CCCCCC',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            interval: this.chartData[0]?.length > 8 ? 4 : 1,
            lineStyle: {
              color: 'rgba(238, 238, 238, 1)',
              type: 'dashed',
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: '#CCCCCC',
            },
          },
        ],
        series: [
          {
            name: 'Post-Click Conversions',
            type: 'bar',
            stack: 'Conversions',
            data: this.chartData[2],
            barWidth: this.chartData[0]?.length > 8 ? 'auto' : '10%',
          },
          {
            name: 'Post-View Conversions',
            type: 'bar',
            stack: 'Conversions',
            data: this.chartData[3],
            barWidth: this.chartData[0]?.length > 8 ? 'auto' : '10%',
          },
          {
            name: 'CVR',
            type: 'line',
            data: this.chartData[1],
            symbolSize: 9,
            symbol: 'circle',
            smooth: true,
            clip: true,
            lineStyle: {
              width: 2.5,
              shadowColor: 'rgba(255, 255, 255, 1)',
              shadowBlur: 2,
              shadowOffsetY: 0,
            },
            itemStyle: {
              color: '#FF634E',
            },
            emphasis: {
              disabled: false,
              itemStyle: {
                color: '#FF634E',
                shadowColor: 'rgba(255, 255, 255, 1)',
                shadowBlur: 2,
              },
            },
            showSymbol: !(this.chartData[0]?.length > 1),
          },
        ],
      };

      return options;
    },
    chartClass(): string {
      let css = 'generic-line-chart';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      return css;
    },

    chartData(): unknown[] {
      if (!this.conversions) {
        return [];
      }
      const dates = [];
      const postClickConversions = [];
      const postViewConversions = [];
      const cvr = [];

      this.conversions.forEach(item => {
        const formattedDate = format(parse(item?.date.replace(/\//g, '-'), 'yyyy-MM-dd', new Date()), 'MMM dd yy');

        dates.push(formattedDate);
        postClickConversions.push(utils.formatNumberWithCommas(item?.postClickConversions));
        postViewConversions.push(utils.formatNumberWithCommas(item?.postViewConversions));
        cvr.push({
          value: parseFloat(item?.cvr?.replace('%', '')) || 0,
          conversions: utils.formatNumberWithCommas(item?.conversions),
        });
      });
      return [dates, cvr, postClickConversions, postViewConversions];
    },
  },
  methods: {
    formatNumberWithCommas(number: number): string {
      return utils.formatNumberWithCommas(number);
    },
    async changeResolution(resolution: Resolution): Promise<void> {
      if (this.isLoading || resolution === this.selectedResolution) return;
      this.selectedResolution = resolution;
      await this.fetchData();
    },
    async fetchData(): Promise<void> {
      const id = this.$route.query?.id || '';
      const daterange = this.$route.query?.daterange || 'alltime';
      const startdate = this.$route.query?.campaignstartdate || '';
      const enddate = this.$route.query?.campaignenddate || '';
      try {
        const data: Conversions = await this.$store.dispatch('performance/getConversions', {
          daterange: daterange,
          advertiserId: id,
          campaignDashboardIds: this.dashboardId,
          resolution: this.selectedResolution,
          startdate: startdate,
          enddate: enddate,
        });
        this.total = data?.total;
        this.conversions = data?.data;
      } catch (error) {
        console.error({ error });
      }
    },
    filterOutKeys(lineSeriesKeys): Array<string> {
      if (!this.hasAdditionalData) {
        lineSeriesKeys = lineSeriesKeys.filter(key => !this.additionalMetrics.includes(key));
      }

      // TODO: temporary solution for PROD-8117, resolve on config level
      if (
        this.isExporting &&
        this.componentConfig?.cid === 'ottDailyPerformanceLineChart' &&
        this?.exportData?.exportLayoutName?.includes('xls')
      ) {
        const hideEmptySeriesKeys = this.componentConfig.hideEmptySeriesKeys;
        if (hideEmptySeriesKeys) {
          const rows = this.sourceData;
          if (!rows) return;
          // remove keys that don't have any data
          hideEmptySeriesKeys.forEach((k: string) => {
            if (!rows.find(x => !!x[k])) {
              lineSeriesKeys = lineSeriesKeys.filter(x => x !== k);
            }
          });
        }
      }
      return lineSeriesKeys;
    },
    getTableData(): void {
      let headers: string[] = [];
      if (this.componentConfig?.exportTweaks?.addDayOfTheWeekColumn) {
        headers.push('Day');
      } else {
        headers.push('n/a'); // can we know?
      }
      const lineSeriesKeys = this.filterOutKeys(this.componentConfig?.lineSeriesKeys);
      headers = [...headers, ...lineSeriesKeys];

      // loop over chartData[0], those are the x axis values
      const data = [];
      if (Array.isArray(this.chartData) && Array.isArray(this.chartData[0])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.chartData[0].forEach((key: any, index: number) => {
          const row = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.chartData.forEach((serie: any[]) => {
            const val = serie[index];
            if (typeof val === 'string') {
              row.push(val.trim());
            } else {
              row.push(val);
            }
          });
          data.push(row);
        });
      }

      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
    initChart(): void {
      if (this.componentConfig.canvasWidth) {
        this.canvasWidth = this.componentConfig.canvasWidth;
      }
      if (this.componentConfig.canvasHeight) {
        this.canvasHeight = this.componentConfig.canvasHeight;
      }
      try {
        setTimeout(() => {
          if (!this.hasEnoughData) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (!this.$refs.canvas) {
            this.$emit('rendered', { empty: true });
            return;
          }
          this.chartInstance = ECharts.init(this.$refs.canvas);
          if (this.chartInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const c = this.chartInstance as any;
            if (this.isXLS) {
              this.getTableData();
            } else {
              c.on('finished', () => {
                this.$emit('rendered', { empty: false });
              });
              c.on('rendered', () => {
                this.$emit('rendered', { empty: false });
              });
            }
            c.setOption(this.chartConfig, true);
          } else {
            // eslint-disable-next-line no-console
            console.error('initChart', 'failed to create instance');
          }
        }, 10);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('initChart', error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          this.chartInstance.resize();
        }
      }, 250);
    },
  },
});
